import { getUid } from '@/firebase/auth'

export function createTagsSumsData(timestamp, currencyCode, month, year, tagId, sum) {
  const months = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0 }

  if (month && months[month + ''] !== undefined && sum) {
    months[month + ''] = +sum
  }

  return {
    currencyCode: currencyCode.toUpperCase(),
    owner: getUid(),
    timestamp: timestamp || new Date(),
    tagId,
    year: +year,
    ...months
  }
}