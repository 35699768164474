export function countFileSize(dataUrl) {
  if (!dataUrl) { return null }

  const sizeInBytes = Math.ceil(4 * Math.ceil((dataUrl.length - (dataUrl.indexOf(';base64,') + 9)) / 3) * 0.5624896334383812)
  return sizeInBytes
}

export const isImageFile = (blob) => {
  if (!blob) { return false }
  return blob.type.split('/')[0] === 'image'
}