import { fetchUrl } from '@/helpers/fetch'

const GOOGLE_CHAT_URL = 'https://chat.googleapis.com/v1/spaces/AAAAhcqtrpE/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=5RFUYFXezQ9t5JGg5g-Uu_WwQ72l-brIeo7Mp9n9Vxs%3D'

export async function sendToGoogleChat(text) {
  if (!text) { return }

  try {
    await fetchUrl(GOOGLE_CHAT_URL, {
      method: "post",
      headers: { 'Content-Type': 'application/json; charset=UTF-8' },
      body: JSON.stringify({
        "text": text
      })
    })
  } catch (error) {
    //
  }
}