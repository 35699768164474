import store from '@/store'
import localizeFilter from '@/filters/localize.filter'

export function setPageTitle(title) {
  let text = ''

  if (title) { text = localizeFilter(title) }
  if (text) { text = text + ' | ' }
  text = text + 'BlueMoneyBox.com'

  document.title = text
}

export function setPageMetaTags() {
  const meta = document.getElementsByTagName('meta')

  if (meta) {
    for (var i = 0; i < meta.length; i++) {
      if (meta[i].name.toLowerCase() === 'description') {
        meta[i].content = 'BlueMoneyBox.com - ' + localizeFilter('OnlinePersonalFinanceTool')
      }

      const property = meta[i].getAttribute('property')
      if (property) {
        if (property.toLowerCase() === 'og:description') {
          meta[i].content = 'BlueMoneyBox.com - ' + localizeFilter('OnlinePersonalFinanceTool')
        }

        if (property.toLowerCase() === 'og:locale') {
          meta[i].content = store.getters.locale
        }
      }
    }
  }
}

export function setPageLang(lang) {
  if (!lang) { return }
  document.documentElement.lang = lang
}