<template>
  <div class="min-h-screen min-w-min h-auto inset-0 bg-gradient-to-r from-emerald-400 dark:from-emerald-300 to-blue-500 dark:to-blue-400">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'PublicLayout'
}
</script>
