export function readLocalStorageData(name) {
  if (!name) { return }

  const localStorageDataStr = localStorage.getItem(name)
  if (!localStorageDataStr) { return null }

  const localStorageData = JSON.parse(localStorageDataStr)
  return localStorageData
}

export function readLocalStorageString(name) {
  if (!name) { return }

  const localStorageStr = localStorage.getItem(name)
  if (!localStorageStr) { return null }

  return localStorageStr
}

export function setLocalStorage({ name, data, string }) {
  if (name) {
    if (data) {
      localStorage.setItem(name, JSON.stringify(data))
    } else if (string) {
      localStorage.setItem(name, string)
    }
  }
}

export function removeLocalStorage(name) {
  if (name) {
    localStorage.removeItem(name)
  }
}