<template>
  <div
    class="min-h-screen min-w-min h-auto inset-0 bg-gradient-to-r"
    :class="{
      'from-emerald-400 dark:from-emerald-300 to-blue-500  dark:to-blue-400': !canUseLimit('setAppColor', false) || !appColor || appColor === 'green-to-blue',
      'from-blue-400 dark:from-blue-300 to-emerald-500 dark:to-emerald-400': canUseLimit('setAppColor', false) && appColor === 'blue-to-green',
      'from-emerald-400 dark:from-emerald-300 to-gray-300 dark:to-gray-200': canUseLimit('setAppColor', false) && appColor === 'green-to-gray',
      'from-blue-400 dark:from-blue-300 to-gray-300 dark:to-gray-200': canUseLimit('setAppColor', false) && appColor === 'blue-to-gray',
      'from-emerald-400 dark:from-emerald-300 to-amber-200 dark:to-amber-100': canUseLimit('setAppColor', false) && appColor === 'green-to-yellow',
      'from-blue-400 dark:from-blue-300 to-red-200 dark:to-red-100': canUseLimit('setAppColor', false) && appColor === 'blue-to-red',
      'from-indigo-400 to-emerald-300 dark:to-emerald-200': canUseLimit('setAppColor', false) && appColor === 'indigo-to-green',
      'from-indigo-400 to-red-300 dark:to-red-200': canUseLimit('setAppColor', false) && appColor === 'indigo-to-red',
      'from-violet-400 dark:from-violet-300 to-red-200 dark:to-red-100': canUseLimit('setAppColor', false) && appColor === 'purple-to-red',
      'from-violet-400 dark:from-violet-300 to-pink-300 dark:to-pink-200': canUseLimit('setAppColor', false) && appColor === 'purple-to-pink',
      'from-gray-400 dark:from-gray-300 to-gray-300 dark:to-gray-200': canUseLimit('setAppColor', false) && appColor === 'gray-to-gray'
    }"
  >
    <div
      v-if="appLoading"
      class="flex justify-center pt-20 app-loader-appear"
    >
      <AppLoader
        :steps="steps"
        :completed-steps="completedSteps"
        @done="loadingFinished"
      />
    </div>

    <div v-else>
      <Drawer :key="locale" />
      <LeftMenu />
      <HeaderPannel />

      <main>
        <span 
          v-if="firebaseQuotaReached"
          class="flex justify-center pt-20 text-gray-700 dark:text-gray-600 text-xl"
        >
          {{ $filters.localizeFilter('TemporariluUnavailable') }}.<br>
          {{ $filters.localizeFilter('TryAgainLater') }}.
        </span>

        <span v-else>
          <router-view />

          <span>
            <Transition name="fade">
              <DataBar
                v-if="showDataBar"
              />
            </Transition>

            <Transition name="fade">
              <div
                v-if="addTransactionAllowed && online && !dataBarIsOpen && !processing"
                :key="'AddTransactionButton-' + lessonStep"
                ref="AddTransactionButton"
                v-tooltip.left="{
                  text: $filters.localizeFilter('AddTransaction') + (lessonStep === 'AddTransactionButton' ? '' : ' (Ctrl + K)'),
                  padding: 4,
                  shown: lessonStep === 'AddTransactionButton',
                  startAfter: 500
                }"
                class="add-transaction-button cursor-pointer"
                :class="{'animation-pulse': lessonStep === 'AddTransactionButton'}"
                @click.prevent.stop="addTransactionButtonClicked('addTransactionRoundedButton')"
                @dblclick.prevent.stop=""
                @contextmenu.prevent.stop=""
              >
                <div class="icon-add icon-add-button icon-blue-light" />
              </div>
            </Transition>

            <AddTransaction
              v-if="addTransactionAllowed && showAddTransaction"
              @close-pannel="showAddTransaction = false"
              @save-button-clicked="addTransaction($event)"
            />

            <EditUserPic v-if="appShow.editUserPic" />
            <Feedback v-if="appShow.feedback" />
            <Lesson v-if="lessonStep" />
            <PayWall v-if="appShow.payWall" />
            <UploadUserPic v-if="appShow.uploadUserPic" />

            <IosBanner
              v-if="showIosBanner"
              @close-pannel="closeIosBanner()"
            />
          </span>
        </span>
      </main>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import { getUid } from '@/firebase/auth'
import { sendToGoogleChat } from '@/helpers/google-chat'
import { readLocalStorageData, readLocalStorageString, setLocalStorage } from '@/helpers/localstorage'
import scrollToTransaction from '@/mixins/scrollToTransaction.mixin'
import AddTransaction from '@/components/views/Transactions/AddTransaction'
import AppLoader from '@/components/UI/loaders/AppLoader'
import DataBar from '@/components/app/DataBar'
import Drawer from '@/components/navigation/Drawer'
import HeaderPannel from '@/components/app/HeaderPannel'
import LeftMenu from '@/components/navigation/LeftMenu'
import useNotifications from '@/composables/useNotifications'

export default {
  name: 'PrivateLayout',
  setup () {
    const { toastify } = useNotifications()
    return { toastify }
  },
  components: {
    AddTransaction, AppLoader, DataBar, Drawer, HeaderPannel, LeftMenu,
    EditUserPic: defineAsyncComponent(() => import('@/components/profile/EditUserPic')),
    Feedback: defineAsyncComponent(() => import('@/components/app/modals/Feedback')),
    IosBanner: defineAsyncComponent(() => import('@/components/app/banners/IosBanner')),
    Lesson: defineAsyncComponent(() => import('@/components/app/Lesson')),
    PayWall: defineAsyncComponent(() => import('@/components/app/modals/PayWall')),
    UploadUserPic: defineAsyncComponent(() => import('@/components/profile/UploadUserPic'))
  },
  mixins: [scrollToTransaction],
  data: () => ({
    appLoading: false,
    completedSteps: 0,
    steps: 13,
    showIosBanner: false,
    maintenanceToast: null,
    maintenanceInterval: null
  }),
  computed: {
    ...mapGetters([
      'appColor',
      'appShow',
      'canUseLimit',
      'country',
      'dataBarIsOpen',
      'defaultAccount',
      'firebaseQuotaReached',
      'getAvailableLimitNumber',
      'lessonStep',
      'locale',
      'maintenance',
      'online',
      'processing',
      'remoteConfig_platform',
      'scrollAllowed',
      'transactions',
      'userStats'
    ]),
    showAddTransaction: {
      get () {
        return this.$store.getters.transactionsShow.add
      },
      set (value) {
        this.$store.commit('setTransactionsShowField', { add: value })
      }
    },
    route () {
      return this.$route.name
    },
    showDataBar () {
      return this.$route.meta.databar
    },
    addTransactionAllowed () {
      return this.$route.meta.addTransactionAllowed
    }
  },
  watch: {
    scrollAllowed (value) {
      if (value) {
        document.body.style.overflow = ''
      } else {
        document.body.style.overflow = 'hidden'
      }
    },
    online (value) {
      if (!value && this.appLoading) {
        this.$router.push({ name: 'Offline' }).catch((e) => { console.log(e)})
      }
    },
    maintenance (value) {
      if (value) {
        if (this.maintenanceInterval) {
          clearInterval(this.maintenanceInterval)
          this.maintenanceInterval = null
        }

        this.removeMaintenanceToast()

        if (value === true) {
          this.$router.push({ name: 'Maintenance' }).catch(() => { })
        } else {
          let textStart = this.$filters.localizeFilter('MaintenanceIn') + ': '
          let textCounter = this.$filters.localizeFilter('Counting').toLowerCase() + '...'

          this.maintenanceToast = this.toastify.info(textStart + textCounter, {
            timeout: null,
            eternal: true
          })

          const closeTimeNum = +value

          this.maintenanceInterval = setInterval(() => {
            const nowNum = Date.now()
            let difference = closeTimeNum - nowNum
            if (difference <= 0) {
              if (this.maintenanceToast) {
                this.toastify.remove(this.maintenanceToast)
                this.maintenanceToast = this.toastify.info(this.$filters.localizeFilter('MaintenanceStarting'), {
                  timeout: null,
                  eternal: true
                })
              }

              if (this.maintenanceInterval) {
                clearInterval(this.maintenanceInterval)
                this.maintenanceInterval = null
              }
            } else {
              textCounter = ''
              if (difference > (1000 * 60 * 60)) {
                const hours = Math.floor(difference / (1000 * 60 * 60))
                if (hours < 10) {
                  textCounter = textCounter + '0'
                }
                textCounter = textCounter + hours + ':'
                difference = difference - (hours * (1000 * 60 * 60))
              }

              if (difference > (1000 * 60)) {
                const minutes = Math.floor(difference / (1000 * 60))
                if (minutes < 10) {
                  textCounter = textCounter + '0'
                }
                textCounter = textCounter + minutes + ':'
                difference = difference - (minutes * (1000 * 60))
              }

              const seconds = Math.floor(difference / 1000)
              if (seconds < 10) {
                textCounter = textCounter + '0'
              }
              textCounter = textCounter + seconds

              let text = textStart + textCounter
              if (this.maintenanceToast) {
                this.toastify.remove(this.maintenanceToast)
                this.maintenanceToast = this.toastify.info(text, {
                  timeout: null,
                  eternal: true
                })
              }
            }
          }, 1000)
        }
      } else {
        this.removeMaintenanceToast()
      }
    }
  },
  created() {
    this.createAppData()
  },
  mounted() {
    document.addEventListener('keydown', this.clickedButton)
  },
  async beforeUnmount() {
    document.removeEventListener('keydown', this.clickedButton)
    await this.$store.dispatch('unsubscribeAll')
  },
  methods: {
    async createAppData () {
      this.appLoading = true

      this.completedSteps = 0
      if (!this.online) { return }
      await require('@/views/Offline.vue')

      this.completedSteps = 1
      if (!this.online) { return }
      const query = await this.$route.query
      if (query && query.show) {
        await this.$store.dispatch('setShow', query.show)

        const newQuery = {}
        for (const key in query) {
          if (key !== 'show') {
            newQuery[key] = query[key]
          }
        }

        this.$router.replace({ query: newQuery }).catch(() => {})
      }

      this.completedSteps = 2
      if (!this.online) { return }
      this.setShowIosBanner()

      this.completedSteps = 3
      if (!this.online) { return }
      await this.$store.dispatch('subscribeToMaintenance')

      this.completedSteps = 4
      if (!this.online) { return }
      if (!this.appColor || !this.defaultCurrency || !this.country || !this.$store.getters.darkMode) {
        const appSettingsAnswer = await this.$store.dispatch('fetchUserSettings')
        if (appSettingsAnswer === 'userNotFound') {
          this.$store.commit('setError', this.$filters.localizeFilter('UserNotFound'))
          await this.$store.dispatch('deleteUserAndLogout', null)
          sendToGoogleChat('*Пользователь не найден:*\n' + getUid())
          this.$router.push({ name: 'Login', query: { type: 'signup' } }).catch(() => { })
          return
        }
      }

      this.completedSteps = 5
      if (!this.online) { return }
      if (!this.$store.getters.logsUnsubscribe) {
        this.$store.dispatch('subscribeToLogs', null)
      }

      this.completedSteps = 6
      if (!this.online) { return }
      if (!this.$store.getters.tarif) {
        await this.$store.dispatch('fetchUserTarif')
      }

      this.completedSteps = 7
      if (!this.online) { return }
      if (!this.$store.getters.loadedAll('userStats')) {
        await this.$store.dispatch('fetchUserStats')
      }

      this.completedSteps = 8
      if (!this.online) { return }
      if (!this.$store.getters.userName || !this.$store.getters.userPic) {
        await this.$store.dispatch('fetchUserProfile')
      }

      this.completedSteps = 9
      if (!this.online) { return }
      if (!Object.keys(this.defaultAccount).length) {
        await this.$store.dispatch('fetchDefaultAccount')
      }

      this.completedSteps = 10
      if (!this.online) { return }
      if (this.$store.getters.userStats('neverLoggedIn')) {
        await this.$store.dispatch('createInitialData')
      }

      this.completedSteps = 11
      if (!this.online) { return }
      if (!this.$store.getters.newsUnsubscribe && this.userStats('newsRead')) {
        this.$store.dispatch('subscribeToNews')
      }

      this.completedSteps = 12
      if (!this.online) { return }
      const lsSessionId = await readLocalStorageString('blue-money-box-com-sessionId-' + getUid())
      if (lsSessionId) {
        this.$store.commit('setSessionId', lsSessionId)
      } else {
        await this.$store.dispatch('createSessionId')
      }

      this.completedSteps = 13
    },
    async setShowIosBanner() {
      const localStorageName = 'blue-money-box-com-hideIosBanner-' + getUid()
      let ls = await readLocalStorageData(localStorageName)
      if (!ls) {
        setLocalStorage({
          name: localStorageName,
          data: {
            timestamp: Date.now()
          }
        })
        return
      }

      if (!ls.hideIosBanner) {
        const userAgent = navigator.userAgent.toLowerCase()
        if (getUid() && userAgent.indexOf('safari') >= 0 && userAgent.indexOf('chrome') < 0 && userAgent.indexOf('edge') < 0 && userAgent.indexOf('edgios') < 0 && userAgent.indexOf('yabrowser') < 0 && userAgent.indexOf('crios') < 0 && userAgent.indexOf('fxios') < 0 && (this.remoteConfig_platform === 'iOS')) {
          this.showIosBanner = true
        }
      }
    },
    async closeIosBanner() {
      this.showIosBanner = false

      const localStorageName = 'blue-money-box-com-hideIosBanner-' + getUid()
      let ls = await readLocalStorageData(localStorageName)
      if (!ls) {
        setLocalStorage({
          name: localStorageName,
          data: {
            timestamp: Date.now(),
            hideIosBanner: Date.now()
          }
        })
        return
      } else {
        ls.hideIosBanner = Date.now()
        setLocalStorage({ name: localStorageName, data: ls })
      }
    },
    loadingFinished() {
      setTimeout(() => {
        this.appLoading = false
        this.completedSteps = 0
      }, 250)
    },
    async addTransaction (data) {
      if (this.lessonStep) { return }
      if (!data || !Array.isArray(data) || !data.length) { return }

      if (+this.getAvailableLimitNumber('transactionsReset', 'transactions', 'maxTransactions') < data.length) {
        this.$store.commit('setAppShowField', { payWall: true })
        return
      }

      if (this.processing) {
        this.toastify.error(this.$filters.localizeFilter('WaitPreviousTask'), {
          icon: 'hourglass_empty'
        })
        return
      }

      if (!this.online) { return }

      const toastId = this.toastify.warning(this.$filters.localizeFilter('SavingTransaction') + '...', { timeout: null })

      const answer = await this.$store.dispatch('createTransactions', data)

      if (answer) {
        let text = this.$filters.localizeFilter('TransactionAdded')
        if (data.length === 2) {
          text = this.$filters.localizeFilter('TransactionsAdded')
        }

        this.toastify.replace(toastId, text, 'success')

        this.scrollToTransaction(answer, true)
      }
    },
    clickedButton (event) {
      if (!event) { return }
      
      if (this.lessonStep) { 
        this.$store.commit('setClickedButton', event)
        return 
      }

      if (event.code === 'Escape') {
        this.$store.dispatch('setCloseAllMenus')
        this.$store.commit('setClickedButton', event)
        return
      }

      if (this.scrollAllowed) {
        if (event.code === 'KeyK' && event.ctrlKey) {
          event.preventDefault()

          if (!this.getAvailableLimitNumber('transactionsReset', 'transactions', 'maxTransactions')) {
            this.$store.commit('setAppShowField', { payWall: true })
            return
          }
          
          if (!this.addTransactionAllowed) { return }
          this.$store.dispatch('setCloseAllMenus')
          setTimeout(() => {
            this.$store.commit('setTransactionsShowField', { add: true })
          }, 0)
          return
        }

        if (event.code === 'KeyA' && event.ctrlKey) {
          event.preventDefault()
          this.$store.commit('setClickedButton', event)
          return
        }

        if (event.code === 'KeyB' && event.ctrlKey) {
          event.preventDefault()
          this.$store.commit('setClickedButton', event)
          return
        }

        if (event.code === 'KeyC' && event.ctrlKey && event.shiftKey) {
          event.preventDefault()
          this.$store.commit('setClickedButton', event)
          return
        }

        if (event.code === 'Backspace' && event.ctrlKey) {
          event.preventDefault()
          this.$store.commit('setClickedButton', event)
          return
        }

        if (event.code === 'Delete' && event.ctrlKey) {
          event.preventDefault()
          this.$store.commit('setClickedButton', event)
          return
        }

        if (['ArrowDown', 'ArrowUp', 'ArrowRight', 'ArrowLeft',].indexOf(event.code) >= 0) {
          if (!this.appShow.feedback && !this.showAddTransaction && !this.appShow.payWall) {
            this.$store.commit('setClickedButton', event)
          }
          return
        }
      }
    },
    removeMaintenanceToast() {
      if (this.maintenanceToast) {
        this.toastify.remove(this.maintenanceToast)
      }
    },
    addTransactionButtonClicked(from) {
      this.$store.dispatch('addTransactionButtonClicked', { from })
    }
  }
}
</script>