const limits = {
  accountName: { max: 30 },
  bankName: { max: 30 },
  cardName: { max: 30 },
  feedback: { min: 25, max: 500 },
  moneyBoxName: { max: 30 },
  password: { min: 6, max: 30 },
  receipt: {
    bytes: 7340032 // 7 * 1024 * 1024
  },
  spreadName: { max: 20 },
  tagComment: { max: 250 },
  tagName: { max: 20 },
  tagsCollectionName: { max: 20 },
  transactionComment: { max: 250 },
  userName: { max: 30 },
  userPic: {
    min: 11,
    max: 500,
    bytes: 5242880 // 5 * 1024 * 1024,
  }
}

export function checkValues(limit, param) {
  if (!limit || !limits[limit] || !param || !limits[limit][param]) { return 0 }
  return limits[limit][param]
}

export function inputLengthString(limit, value) {
  if (!value) { value = '' }
  if (!limit || !limits[limit] || !limits[limit].max) { return '' }
  return `${value.length} / ${limits[limit].max}`
}